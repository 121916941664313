<template>
  <!-- 接线图管理界面 -->
  <div class="create-screen">
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <div class="table-layout">
        <el-row :gutter="20">
          <el-form
            ref="siteForm"
            :model="siteForm"
            label-width="100px"
            :inline="true"
          >
            <el-row class="btnRow">
              <el-col :span="5" class="elrow">
                <el-form-item
                  label="唯一编码"
                  prop="iditifyId"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="siteForm.iditifyId"
                    size="mini"
                    clearable
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="设备类型" prop="xslx">
                  <el-select
                    v-model="siteForm.type"
                    size="mini"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="item in typeoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="在线状态" prop="xsxz">
                  <el-select
                    v-model="siteForm.status"
                    size="mini"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="item in onlineOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <search-button
                v-show="showAll"
                :showAll="showAll"
                @clearAll="clearAll"
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                @closepop="closepop"
              ></search-button>
            </el-row>
            <el-row :class="!showAll ? 'btnRow' : 'unBtnRow'">
              <el-col :span="5" :class="!showAll ? 'btnRow' : 'unBtnRow'">
                <el-form-item
                  label="规格型号"
                  prop="xinghao"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="siteForm.xinghao"
                    size="mini"
                    clearable
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="使用状态" prop="xslx">
                  <el-select
                    v-model="siteForm.uploadStatus"
                    size="mini"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="item in uploadoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :class="!showAll ? 'btnRow' : 'unBtnRow'">
                <el-form-item
                  label="安装位置"
                  prop="xinghao"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="siteForm.xinghao"
                    size="mini"
                    clearable
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <SearchButton
                v-show="!showAll"
                :showAll="showAll"
                @clearAll="clearAll"
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                @closepop="closepop"
              ></SearchButton>
            </el-row>
          </el-form>
        </el-row>
        <br />

        <el-row style="margin-bottom: 20px">
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              v-if="hasPermission('add')"
              type="text"
              icon="el-icon-plus"
              size="mini"
              class="editBtn"
              @click="addVisiableSensor"
              >新增</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('edit')"
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="editSensor"
              >修改</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('delete')"
              style="width: 80px"
              type="text"
              class="deleteBtn"
              icon="el-icon-delete"
              size="mini"
              @click="delSensor"
              >删除</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="yList"
          :cell-style="{ 'text-align': 'center' }"
          :row-style="getRowClass"
          :header-row-style="getRowClass"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChangeNode"
          border
          height="400px"
          style="width: 100%;height: 400px"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column min-width="4%" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column min-width="8%" label="站点ID" v-if="false"
            ><template slot-scope="scope">{{
              scope.row.site_transformer_id
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="一次接线图id" v-if="false"
            ><template slot-scope="scope">{{
              scope.row.primary_wiring_diagram
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="备注"
            ><template slot-scope="scope">{{
              scope.row.explain
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="一次接线图代码"  v-if="false"
            ><template slot-scope="scope">{{
              scope.row.primary_wiring_diagram
            }}</template></el-table-column
          >
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="pageSize"
            layout="prev,pager,next,total,jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="centerDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="150px"
              :model="siteForm"
            >
              <el-form-item label="类型:" :required="true" prop="entity">
              </el-form-item>
              <el-form-item label="上传xlsx文件:">
                <el-upload
                  ref="upload"
                  action="#"
                  :auto-upload="false"
                  :multiple="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  class="upload-demo uploadImages"
                  :http-request="uploadHttpRequest"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                >
                  <el-button slot="trigger" class="btn-text" type="primary"
                    >选取文件</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="btn-text" @click="submitUpload"
                  >上传</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
      <!--新增单条网关信息管理-->
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="singleAddDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="openDigl">
            <div class="item1">
              <br />
              <el-form
                label-position="left"
                label-width="100px"
                :model="siteForm"
              >
                <el-form-item label="站点:" :required="true">
                  <el-select
                    v-model="siteForm.site_transformer_id"
                    size="mini"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="item in zdoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="备注:"
                  :required="true"
                >
                  <el-input v-model="siteForm.explain"></el-input>
                </el-form-item>
                <el-form-item
                  label="代码:"
                  :required="true"
                >
                  <el-input type="textarea" :rows="4" v-model="siteForm.primary_wiring_diagram"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn-text"
                    @click="handleClick"
                    >{{ addorEdittext }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getAllSites,
  getAlljxtInfo,
  addjxtInfo,
  deleteAlljxtInfo,
} from "@/api/index";
import SearchButton from "./tool/button.vue";
export default {
  data() {
    return {
      fullscreenLoading: false,
      pageSize: 20,
      currentPage1: 1,
      selctionNodeRows: [],
      nodeids: [],
      centerDialogVisible: false,
      singleAddDialogVisible: false,
      shouquanDialogVisiable: false,
      addorEdittext: "新增",
      addorEditshoquan: "绑定",
      siteForm: {
        site_transformer_id: "",
        explain: "",
        primary_wiring_diagram: ""
      },
      menuProps: {
        label: "equipment_name",
        children: "children",
      },
      defaultKey: [],
      arrBegin: [],
      fileList: [],
      tableData: [],
      originTableData: [],
      menuList: [],
      // 选中数组
      ids: [],
      selctionRows: [],
      yList: [],
      zdoptions: [],
      typeoptions: [
        { id: "1", name: "tev" },
        { id: "2", name: "ae" },
        { id: "3", name: "hfct" },
        { id: "4", name: "tem" },
      ],
      onlineOptions: [
        { label: "在线", value: "1" },
        { label: "离线", value: "2" },
        { label: "故障", value: "3" },
      ],
      uploadoptions: [
        { id: "1", name: "正常" },
        { id: "2", name: "异常" },
        { id: "3", name: "中断" },
      ],
      showAll: true,
    };
  },
  components: {
    SearchButton,
  },
  computed: {
    tableHeight() {
      if (this.showAll) {
        return window.innerHeight - 140;
      } else {
        return window.innerHeight - 200;
      }
    },
  },
  mounted() {
    this.getSensorType();
  },
  filters: {
    timeFormat: (value) => {
      // return value ? value.substring(0,value.indexOf(".")).replace('T',' ') : ''
      return value ? value.replace("T", " ") : "";
    },
  },
  methods: {
    // 加载表格数据 查询接线图信息
    getSensorType() {
      getAlljxtInfo()
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            //   this.tableData = sortBy(this.tableData, ["upload_time"]).reverse();
            this.originTableData = this.tableData;
            this.yList = this.tableData.slice(
              (this.currentPage1 - 1) * this.pageSize,
              this.currentPage1 * this.pageSize
            );
          } else {
            this.$message.error(res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hasPermission(permissionName) {
      let elementUi = [];
      let arr = this.formatTree(this.$store.getters.permissions, "0");
      arr.forEach((v) => {
        if (v.attribute_name == "台账管理") {
          v.children.forEach((m) => {
            if (m.attribute_name == "站点管理") {
              m.children.forEach((j) => {
                elementUi.push(j.remarks);
              });
            }
          });
        }
      });
      return elementUi.includes(permissionName);
    },
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 文件上传之前的操作
    beforeUpload(file) {
      // 文件大小限制为20M
      const fileLimit = file.size / 1024 / 1024 < 2000;
      if (!fileLimit) {
        console.log("上传文件大小不超过2000M！");
      }
      // 返回判断条件,false停止上传
      // return fileType && fileLimit;
      return fileLimit;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 新增传感器弹窗信息
    addVisiableSensor() {
      this.addorEdittext = "新增";
      this.zdoptions = []
      this.siteForm = {
        site_transformer_id: "",
        explain: "",
        primary_wiring_diagram: "",
      };
      getAllSites().then(res=>{
        if(res.code == 200){
          res.data.forEach(v=>{
            this.zdoptions.push ({
              label: v.site_name,
              value: v.site_transformer_id
            })
          })
        }
      }).catch(err=>{
        console.log(err)
      })
      this.singleAddDialogVisible = true;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      // FormData对象
      let formData = new FormData();
      formData.append("file", param.file);
      // formData.append("entity", this.nodeForm.entity);
      uploadFormData(formData)
        .then((res) => {
          var msg = "";
          if (res.code === 200) {
            this.$message.success("上传成功");
            // this.nodeForm.entity = "";
            this.fileList = [];
            this.centerDialogVisible = false;
            // this.getTableData();
          } else {
            msg = res.error;
            this.$message.error("上传失败！原因是" + msg);
            this.fileList = [];
          }
        })
        .catch((err) => {
          this.$refs.upload.clearFiles();
          this.fileList = [];
        });
    },
    // 上传文件
    submitUpload() {
      // 判断是否选择了文件，若没有，则后边支持导入
      if (this.fileList.length < 1) {
        this.$message.warning("请选择上传的文件！");
      } else {
        // 显示散点图谱
        this.centerDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      }
    },
    // 新增接线图
    addSensor() {
      addjxtInfo(this.siteForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.siteForm.site_transformer_id =
          this.selctionNodeRows[0].site_transformer_id;
        this.siteForm.explain = this.selctionNodeRows[0].explain;
        this.siteForm.primary_wiring_diagram = this.selctionNodeRows[0].primary_wiring_diagram;
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个角色
    delSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行删除操作！");
      } else {
        deleteAlljxtInfo({
          primary_wiring_diagram_id: this.selctionNodeRows[0].primary_wiring_diagram_id,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getSensorType();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#3f5c6d2c;text-align:center";
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1;
      this.pageSize = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改变电站
    relationEdit() {
      // 使用链接put 修改
      let params = {
        site_transformer_id: this.siteForm.site_transformer_id,
        explain: this.siteForm.explain,
        primary_wiring_diagram: this.siteForm.primary_wiring_diagram
      };
      addjxtInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getSensorType();
        }
      });
    },
    editBtn(row) {
      console.log(row);
    },
    deleteBtn(row) {
      console.log(row);
    },
    closepop() {
      this.showAll = !this.showAll;
    },

    searchSenser() {
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.siteForm);
    },
    // 重置
    clearAll() {},
    importEvent() {
      // 打开文件导入的弹窗
      this.centerDialogVisible = true;
    },
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.btnRow {
  display: block;
}

.unBtnRow {
  display: none;
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
::v-deep .el-textarea__inner {
  background-color: transparent;
  border: 1px solid #409eff;;
}
::v-deep .el-tree {
  position: relative;
  cursor: pointer;
  background: transparent;
  color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background: #6f9ec9;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgb(20, 107, 107) !important;
}
</style>

